import React from 'react';
import Layout from '../../../components/Layout';
import NavOne from '../../../components/NavOne';
import { Spoiler } from '@mantine/core';
import PageHeader from '../../../components/PageHeader';
import Footer from '../../../components/Footer';

import g1 from '../../../assets/images/2023/capping-and-strapping-ceremony/1.jpg';
import g2 from '../../../assets/images/2023/capping-and-strapping-ceremony/2.jpg';
import g3 from '../../../assets/images/2023/capping-and-strapping-ceremony/3.jpg';
import g4 from '../../../assets/images/2023/capping-and-strapping-ceremony/4.jpg';
import g5 from '../../../assets/images/2023/capping-and-strapping-ceremony/5.jpg';
import g6 from '../../../assets/images/2023/capping-and-strapping-ceremony/6.jpg';
import g7 from '../../../assets/images/2023/capping-and-strapping-ceremony/7.jpg';
import g8 from '../../../assets/images/2023/capping-and-strapping-ceremony/8.jpg';
import g9 from '../../../assets/images/2023/capping-and-strapping-ceremony/9.jpg';
import g10 from '../../../assets/images/2023/capping-and-strapping-ceremony/10.jpg';
import g11 from '../../../assets/images/2023/capping-and-strapping-ceremony/11.jpg';
import g12 from '../../../assets/images/2023/capping-and-strapping-ceremony/12.jpg';
import g13 from '../../../assets/images/2023/capping-and-strapping-ceremony/13.jpg';
import g14 from '../../../assets/images/2023/capping-and-strapping-ceremony/14.jpg';
import g15 from '../../../assets/images/2023/capping-and-strapping-ceremony/15.jpg';
import g16 from '../../../assets/images/2023/capping-and-strapping-ceremony/16.jpg';
import g17 from '../../../assets/images/2023/capping-and-strapping-ceremony/17.jpg';
import g18 from '../../../assets/images/2023/capping-and-strapping-ceremony/18.jpg';
import g19 from '../../../assets/images/2023/capping-and-strapping-ceremony/19.jpg';
import g20 from '../../../assets/images/2023/capping-and-strapping-ceremony/20.jpg';
import g21 from '../../../assets/images/2023/capping-and-strapping-ceremony/21.jpg';
import g22 from '../../../assets/images/2023/capping-and-strapping-ceremony/22.jpg';
import g23 from '../../../assets/images/2023/capping-and-strapping-ceremony/23.jpg';
import g24 from '../../../assets/images/2023/capping-and-strapping-ceremony/24.jpg';

const BlogLayout = () => {
  return (
    <section className='gallery-one'>
      <div className='container'>
        <div className='row'>
          <div className='col-lg-12'>
            <div className='course-details__top-left'>
              <h2 className='course-details__title'>
                College of Nursing Services Capping and Strapping Ceremony
              </h2>
            </div>
          </div>
          <Spoiler maxHeight={280} hideLabel='Hide' showLabel='Show More'>
            <div className='row'>
              <div className='col-lg-4 col-md-6'>
                <div className='gallery-one__single'>
                  <img src={g1} alt='' />
                  <a href={g1} className='gallery-one__popup img-popup'>
                    <i className='kipso-icon-magnifying-glass'></i>
                  </a>
                </div>
              </div>
              <div className='col-lg-4 col-md-6'>
                <div className='gallery-one__single'>
                  <img src={g2} alt='' />
                  <a href={g2} className='gallery-one__popup img-popup'>
                    <i className='kipso-icon-magnifying-glass'></i>
                  </a>
                </div>
              </div>
              <div className='col-lg-4 col-md-6'>
                <div className='gallery-one__single'>
                  <img src={g3} alt='' />
                  <a href={g3} className='gallery-one__popup img-popup'>
                    <i className='kipso-icon-magnifying-glass'></i>
                  </a>
                  {/* <span style={{ fontSize: 10, fontStyle: 'italic' }}>
                    Prof Njideka Okubadejo. receiving an award from the CMD
                  </span> */}
                </div>
              </div>
              <div className='col-lg-4 col-md-6'>
                <div className='gallery-one__single'>
                  <img src={g4} alt='' />
                  <a href={g4} className='gallery-one__popup img-popup'>
                    <i className='kipso-icon-magnifying-glass'></i>
                  </a>
                </div>
              </div>
              <div className='col-lg-4 col-md-6'>
                <div className='gallery-one__single'>
                  <img src={g5} alt='' />
                  <a href={g5} className='gallery-one__popup img-popup'>
                    <i className='kipso-icon-magnifying-glass'></i>
                  </a>
                  {/* <span style={{ fontSize: 10, fontStyle: 'italic' }}>
                    Prof Njideka Okubadejo. Prof. of Medicine and Consultant
                    Neurologist. LUTH.
                  </span> */}
                </div>
              </div>
              <div className='col-lg-4 col-md-6'>
                <div className='gallery-one__single'>
                  <img src={g6} alt='' />
                  <a href={g6} className='gallery-one__popup img-popup'>
                    <i className='kipso-icon-magnifying-glass'></i>
                  </a>
                </div>
              </div>
              <div className='col-lg-4 col-md-6'>
                <div className='gallery-one__single'>
                  <img src={g7} alt='' />
                  <a href={g7} className='gallery-one__popup img-popup'>
                    <i className='kipso-icon-magnifying-glass'></i>
                  </a>
                </div>
              </div>
              <div className='col-lg-4 col-md-6'>
                <div className='gallery-one__single'>
                  <img src={g8} alt='' />
                  <a href={g8} className='gallery-one__popup img-popup'>
                    <i className='kipso-icon-magnifying-glass'></i>
                  </a>
                </div>
              </div>
              <div className='col-lg-4 col-md-6'>
                <div className='gallery-one__single'>
                  <img src={g9} alt='' />
                  <a href={g9} className='gallery-one__popup img-popup'>
                    <i className='kipso-icon-magnifying-glass'></i>
                  </a>
                </div>
              </div>
              <div className='col-lg-4 col-md-6'>
                <div className='gallery-one__single'>
                  <img src={g10} alt='' />
                  <a href={g10} className='gallery-one__popup img-popup'>
                    <i className='kipso-icon-magnifying-glass'></i>
                  </a>
                </div>
              </div>
              <div className='col-lg-4 col-md-6'>
                <div className='gallery-one__single'>
                  <img src={g11} alt='' />
                  <a href={g11} className='gallery-one__popup img-popup'>
                    <i className='kipso-icon-magnifying-glass'></i>
                  </a>
                  {/* <span style={{ fontSize: 10, fontStyle: 'italic' }}>
                    Dr Tolu Olufunlayo. Consultant Public Health Physician.
                    LUTH.
                  </span> */}
                </div>
              </div>
              <div className='col-lg-4 col-md-6'>
                <div className='gallery-one__single'>
                  <img src={g12} alt='' />
                  <a href={g12} className='gallery-one__popup img-popup'>
                    <i className='kipso-icon-magnifying-glass'></i>
                  </a>
                  {/* <span style={{ fontSize: 10, fontStyle: 'italic' }}>
                    Prof Oyinkan Sofola. Chairman Luth Health Research & Ethics
                    Committee.
                  </span> */}
                </div>
              </div>
              <div className='col-lg-4 col-md-6'>
                <div className='gallery-one__single'>
                  <img src={g13} alt='' />
                  <a href={g13} className='gallery-one__popup img-popup'>
                    <i className='kipso-icon-magnifying-glass'></i>
                  </a>
                </div>
              </div>
              <div className='col-lg-4 col-md-6'>
                <div className='gallery-one__single'>
                  <img src={g14} alt='' />
                  <a href={g14} className='gallery-one__popup img-popup'>
                    <i className='kipso-icon-magnifying-glass'></i>
                  </a>
                </div>
              </div>
              <div className='col-lg-4 col-md-6'>
                <div className='gallery-one__single'>
                  <img src={g15} alt='' />
                  <a href={g15} className='gallery-one__popup img-popup'>
                    <i className='kipso-icon-magnifying-glass'></i>
                  </a>
                </div>
              </div>
              <div className='col-lg-4 col-md-6'>
                <div className='gallery-one__single'>
                  <img src={g16} alt='' />
                  <a href={g16} className='gallery-one__popup img-popup'>
                    <i className='kipso-icon-magnifying-glass'></i>
                  </a>
                </div>
              </div>
              <div className='col-lg-4 col-md-6'>
                <div className='gallery-one__single'>
                  <img src={g17} alt='' />
                  <a href={g17} className='gallery-one__popup img-popup'>
                    <i className='kipso-icon-magnifying-glass'></i>
                  </a>
                  {/* <span style={{ fontSize: 10, fontStyle: 'italic' }}>
                    Dr Maxwell Ubah. CEO Strategy House. Leadership and
                    management Consultant.
                  </span> */}
                </div>
              </div>
              <div className='col-lg-4 col-md-6'>
                <div className='gallery-one__single'>
                  <img src={g18} alt='' />
                  <a href={g18} className='gallery-one__popup img-popup'>
                    <i className='kipso-icon-magnifying-glass'></i>
                  </a>
                </div>
              </div>
              <div className='col-lg-4 col-md-6'>
                <div className='gallery-one__single'>
                  <img src={g19} alt='' />
                  <a href={g19} className='gallery-one__popup img-popup'>
                    <i className='kipso-icon-magnifying-glass'></i>
                  </a>
                </div>
              </div>
              <div className='col-lg-4 col-md-6'>
                <div className='gallery-one__single'>
                  <img src={g20} alt='' />
                  <a href={g20} className='gallery-one__popup img-popup'>
                    <i className='kipso-icon-magnifying-glass'></i>
                  </a>
                </div>
              </div>
              <div className='col-lg-4 col-md-6'>
                <div className='gallery-one__single'>
                  <img src={g21} alt='' />
                  <a href={g21} className='gallery-one__popup img-popup'>
                    <i className='kipso-icon-magnifying-glass'></i>
                  </a>
                </div>
              </div>
              <div className='col-lg-4 col-md-6'>
                <div className='gallery-one__single'>
                  <img src={g22} alt='' />
                  <a href={g22} className='gallery-one__popup img-popup'>
                    <i className='kipso-icon-magnifying-glass'></i>
                  </a>
                </div>
              </div>
              <div className='col-lg-4 col-md-6'>
                <div className='gallery-one__single'>
                  <img src={g23} alt='' />
                  <a href={g23} className='gallery-one__popup img-popup'>
                    <i className='kipso-icon-magnifying-glass'></i>
                  </a>
                </div>
              </div>
              <div className='col-lg-4 col-md-6'>
                <div className='gallery-one__single'>
                  <img src={g24} alt='' />
                  <a href={g24} className='gallery-one__popup img-popup'>
                    <i className='kipso-icon-magnifying-glass'></i>
                  </a>
                </div>
              </div>
            </div>
          </Spoiler>
        </div>
      </div>
    </section>
  );
};

const EventPage = () => {
  return (
    <Layout pageTitle='Event Gallery | LUTH'>
      <NavOne />
      <PageHeader title='' />
      <BlogLayout />
      <Footer />
    </Layout>
  );
};

export default EventPage;
